// use trailing slash if its get by params

class CONSTANT {
  static NOTIFICATION_DURATION = 2000
  static DEFAULT_PAGINATION_PER_PAGE = 10
  static DEFAULT_PAGINATION_MAXIMUM_DOWNLOAD = 999999
  static DEFAULT_RESYNC_MESSAGE = 'Data will be automatically updated in 5-15 minutes'
  static DEFAULT_DROPDOWN_PAYLOAD = {
    start: 0,
    length: 999999
  }
  static DEFAULT_LANGUAGE = 'ID'
  static DEFAULT_DOWNLOAD_TYPES = ['csv', 'xlsx', 'pdf']
  static DEFAULT_DOWNLOAD_TEMPLATE = ['csv']
  static DEFAULT_REGION_AUTH_FIELD = ['province', 'city', 'district', 'subDistrict']
  static DEFAULT_REGION_DMS_FIELD = ['region', 'city', 'address', 'postalCode']
  static DEFAULT_LOCATION_DEALER_FIELD = [
    'dealerCode',
    'dealerType',
    'dealerStatus',
    'name',
    'region',
    'province',
    'city'
  ]
  static DEFAULT_QR_PROMO_CODE = 'promoCode#'
  static DEFAULT_FAQ_CATEGORY_FIELD = ['name']
  static DEFAULT_FAQ_LIST_FIELD = ['category', 'question', 'shortAnswer']
  static DEFAULT_HTML_FIELD = ['language', 'body', 'description']
  static DEFAULT_EVENT_FIELD = ['eventName', 'city', 'status']
  static DEFAULT_PARTNER_CATEGORY_FIELD = ['partnerName', 'city', 'status']
  static DEFAULT_PARTNER_LIST_FIELD = ['branchName', 'address', 'city', 'postalCode']
  static DEFAULT_ACCESS_CONTROL_FIELD = ['fullName', 'email', 'userName', 'status']
  static DEFAULT_ROLES_MANAGEMENT_FIELD = ['fullName', 'email', 'roleName']
  static DEFAULT_ROLES_LIST_FIELD = ['name', 'description']
  static DEFAULT_VOUCHER_CATEGORY_FIELD = ['nameEn', 'description', 'status', 'image', 'icon']
  static DEFAULT_TOKEN_CATEGORY_FIELD = [
    'nameEn',
    'nameId',
    'status',
    'companyName',
    'companyShortName',
    'monetaryValue'
  ]
  static DEFAULT_TOKEN_REQUEST_FIELD = ['requestName', 'voucherName', 'tokenCategory', 'partner']
  static DEFAULT_TOKEN_SUB_REQUEST_FIELD = ['code', 'voucherName', 'partnerName', 'statusRequest']

  static DEFAULT_VOUCHER_LIST_FIELD = [
    'title',
    'visibility',
    'partnerName',
    'tokenCategory',
    'applicableAreaMode',
    'monetaryValueString',
    'status'
  ]

  static DEFAULT_VOUCHER_FAQ_FIELD = ['title', 'partnerName', 'validFrom', 'validUntil']

  static DEFAULT_TOKEN_IMPORT_FIELD = ['fileName', 'voucherName', 'tokenCategory', 'partnerName']
  static DEFAULT_TOKEN_LIST_FIELD = [
    'code',
    'partnerName',
    'voucherName',
    'voucherTokenCategory',
    'purchasedBy',
    'memberCode',
    'purchased',
    'claimed',
    'revoked',
    'validFrom',
    'validUntil',
    'campaignName',
    'expired',
    'source'
  ]
  static DEFAULT_EVENT_PRIZE_REDEMPTION_FIELD = ['eventName', 'prizeTag', 'fullName', 'prizePoint']
  static DEFAULT_VOUCHER_BENEFITS_FIELD = [
    'voucherCampaignName',
    'voucherName',
    'benefitCategoryName',
    'memberTier',
    'partner'
  ]
  static DEFAULT_VOUCHER_SUBCATEGORY_FIELD = ['nameEn', 'description', 'status', 'nameId']
  static DEFAULT_CAR_INFORMATION_FIELD = [
    'modelName',
    'fullName',
    'vin',
    'memberId',
    'year',
    'licensePlateNumber',
    'statusName',
    'createdAtFormated',
    'approvalDateFormated',
    'rejectedDateFormated'
  ]
  static DEFAULT_TRANSACTION_FIELD = [
    'eventName',
    'userId',
    'userFullName',
    'dmsRepairId',
    'dealerName',
    'serviceDate',
    'contactName',
    'serviceStatus',
    'appointmentConfirmation'
  ]
  static DEFAULT_NOTIFICATION_CUSTOMER_FIELD = ['description']
  static DEFAULT_VOUCHER_PACKAGE_FIELD = [
    'packageName',
    'packageCategoryName',
    'voucherList',
    'carModel'
  ]
  static DEFAULT_EV_TOPUP_FIELD = [
    'key',
    'transactionId',
    'createdAtFormat',
    'paymentMethodName',
    'status',
    'memberId',
    'grandTotal'
  ]

  static DEFAULT_VOUCHER_GIFT_FIELD = [
    'memberCode',
    'voucherGiftName',
    'voucherName',
    'assignedTo',
    'assignedBy',
    'sendStatus'
  ]

  static DEFAULT_GIFT_REDEMPTION_FIELD = [
    'voucherGiftName',
    'voucherName',
    'redeemedBy',
    'partnerName'
  ]

  static DEFAULT_COIN_FIELD = [
    'Description',
    'Key',
    'UserId',
    'FullName',
    'MemberCode',
    'MemberTier',
    'Email'
  ]

  static URL_BASE = process.env.REACT_APP_URL_BASE

  static URL_LOGIN = '/auth/api/v1/LoginWebAdmin'
  static URL_LOGOUT = '/auth/api/v1/logout'
  static URL_REFRESH = '/auth/api/v1/refreshToken'
  static URL_VALID = '/auth/api/v1/validToken'
  static URL_OTP_REQUEST = process.env.REACT_APP_ENDPOINT_OTP_REQUEST
  static URL_OTP_VALIDATE = process.env.REACT_APP_ENDPOINT_OTP_VALIDATION

  static URL_MENU_TREE = '/auth/api/v1/WebAdmin/MenuTree'
  static URL_MENU_PERMISSION = '/auth/api/v1/MyMenuPermission'
  static URL_MENU_CHANGE_PASSWORD = '/auth/api/v1/WebAdmin/UserAcc/ChangePassword'
  static URL_PROVINCE = '/auth/api/v1/Province/getAll/0'
  static URL_CITY = '/auth/api/v1/City/getAll/0'
  static URL_CAR_MODEL_DROPDOWN = '/core/api/v1/CarModel/ddlcarmodel'
  static URL_YEARS_DROPDOWN = '/core/api/v1/Car/ddlyear'
  static URL_EV_CHARGER_POINT_DROPDOWN = '/ev/api/v1/master/charger/points/ddl'
  static URL_EV_CHARGER_GROUP_DROPDOWN = '/ev/api/v1/master/charger/group/'
  static URL_EV_CHARGER_BOX_DROPDOWN = '/ev/api/v1/master/charger/box/'
  static URL_CAR_BRAND_DROPDOWN = '/core/api/v1/CarBrand'
  static URL_MEMBER_TYPE_DROPDOWN = '/auth/api/v1/MemberType/DropdownList'
  static URL_USERNAME_DROPDOWN = '/auth/api/v1/WebAdmin/User/DropdownList'

  static URL_GENERAL_SOCKET_TYPE = '/ev/api/v1/master/socket/type/ddl'
  static URL_GENERAL_STATUS_CHARGING = '/ev/api/v1/master/status/charging/ddl'
  static URL_GENERAL_STATUS_CHARGING_REQUEST = '/ev/api/v1/master/status/charging/request/ddl'

  static URL_REGION_DATA_AUTH = '/auth/api/v1/SubDistrict/WebAdmin'
  static URL_REGION_DATA_AUTH_PROVINCE = '/auth/api/v1/province/WebAdmin'
  static URL_REGION_DATA_AUTH_CITY = '/auth/api/v1/city/WebAdmin'
  static URL_REGION_DATA_AUTH_DISTRICT = '/auth/api/v1/district/WebAdmin'
  static URL_REGION_DATA_AUTH_SUBDISTRICT = '/auth/api/v1/SubDistrict/WebAdmin'
  static URL_REGION_DATA_AUTH_PROVINCE_DROPDOWN = '/auth/api/v1/province/WebAdmin/Dropdown'
  static URL_REGION_DATA_AUTH_CITY_DROPDOWN = '/auth/api/v1/city/WebAdmin/Dropdown/'
  static URL_REGION_DATA_AUTH_DISTRICT_DROPDOWN = '/auth/api/v1/district/WebAdmin/Dropdown/'
  static URL_REGION_DATA_AUTH_SUBDISTRICT_DROPDOWN = '/auth/api/v1/SubDistrict/WebAdmin/Dropdown/'
  static URL_REGION_DATA_AUTH_DETAIL = '/auth/api/v1/SubDistrict/WebAdmin/'
  static URL_REGION_DATA_AUTH_DOWNLOAD = '/auth/api/v1/SubDistrict/WebAdmin/download'
  static URL_REGION_DATA_AUTH_ACTIVATE = '/auth/api/v1/SubDistrict/WebAdmin/Activate'

  static URL_REGION_DATA_DMS = '/core/api/v1/WebAdmin/RegionDMS/List'
  static URL_REGION_DATA_DMS_PROVINCE = '/core/api/v1/WebAdmin/RegionDMS/Province'
  static URL_REGION_DATA_DMS_CITY = '/core/api/v1/WebAdmin/RegionDMS/City'
  static URL_REGION_DATA_DMS_DETAIL = '/core/api/v1/WebAdmin/RegionDMS/Detail/'
  static URL_REGION_DATA_DMS_DOWNLOAD = '/core/api/v1/WebAdmin/RegionDMS/Download'
  static URL_REGION_DATA_DMS_ACTIVATE = '/core/api/v1/WebAdmin/RegionDMS/Activate'

  static URL_LOCATION_DEALER = '/core/api/v1/Dealer/ServiceWebAdmin'
  static URL_LOCATION_DEALER_DETAIL = '/core/api/v1/Dealer/ServiceWebAdmin/'
  static URL_LOCATION_DEALER_DROPDOWN = '/core/api/v1/Dealer/ServiceWebAdmin/DropdownListDealer'
  static URL_LOCATION_DEALER_ACTIVATE = '/core/api/v1/Dealer/ServiceWebAdmin/Activate'
  static URL_LOCATION_DEALER_SYNC = '/core/api/v1/Dealer/ServiceWebAdmin/Sync'
  static URL_LOCATION_DEALER_DOWNLOAD = '/core/api/v1/Dealer/Download'

  static URL_LOCATION_EVENT = '/core/api/v1/WebAdmin/EventLocation'
  static URL_LOCATION_EVENT_DOWNLOAD = '/core/api/v1/WebAdmin/EventLocation/Download'
  static URL_LOCATION_EVENT_UPDATE = '/core/api/v1/WebAdmin/EventLocation/Update'
  static URL_LOCATION_EVENT_DETAIL = '/core/api/v1/WebAdmin/EventLocation/Detail'
  static URL_LOCATION_EVENT_UPLOAD = '/core/api/v1/WebAdmin/EventLocation/Upload'
  static URL_LOCATION_EVENT_CREATE = '/core/api/v1/WebAdmin/EventLocation/Create'
  static URL_LOCATION_EVENT_EDIT = '/core/api/v1/WebAdmin/EventLocation/UpdateData'
  static URL_LOCATION_EVENT_DOWNLOAD_TEMPLATE =
    '/core/api/v1/WebAdmin/EventLocation/Upload/Template'
  static URL_LOCATION_EVENT_DELETE = '/core/api/v1/WebAdmin/EventLocation/Delete'

  static URL_LOCATION_PARTNER = '/core/api/v1/WebAdmin/CompanyList'
  static URL_LOCATION_PARTNER_DOWNLOAD = '/core/api/v1/WebAdmin/CompanyList/Download'
  static URL_LOCATION_PARTNER_DROPDOWN = '/core/api/v1/WebAdmin/CompanyList/DropdownList'
  static URL_LOCATION_PARTNER_UPLOAD = '/core/api/v1/WebAdmin/CompanyList/Upload'
  static URL_LOCATION_PARTNER_STATUS = '/core/api/v1/WebAdmin/CompanyList/Enable'
  static URL_LOCATION_PARTNER_CREATE = '/core/api/v1/WebAdmin/CompanyList/Create'
  static URL_LOCATION_PARTNER_UPDATE = '/core/api/v1/WebAdmin/CompanyList/Update'
  static URL_LOCATION_PARTNER_UPDATE_FAQ = '/core/api/v1/WebAdmin/CompanyList/Update/FAQ'
  static URL_LOCATION_PARTNER_DETAIL = '/core/api/v1/WebAdmin/Company/'
  static URL_LOCATION_PARTNER_BRANCH = '/core/api/v1/WebAdmin/Branch/List'
  static URL_LOCATION_PARTNER_BRANCH_DOWNLOAD = '/core/api/v1/WebAdmin/Branch/Download'
  static URL_LOCATION_PARTNER_BRANCH_STATUS = '/core/api/v1/WebAdmin/Branch/Enable'
  static URL_LOCATION_PARTNER_BRANCH_DETAIL = '/core/api/v1/WebAdmin/Branch/Detail/'
  static URL_LOCATION_PARTNER_BRANCH_UPDATE = '/core/api/v1/WebAdmin/Branch/Update'
  static URL_LOCATION_PARTNER_DOWNLOAD_TEMPLATE =
    '/core/api/v1/WebAdmin/CompanyList/Upload/Template'
  static URL_LOCATION_PARTNER_BRANCH_CREATE = '/core/api/v1/WebAdmin/Branch/Create'
  static URL_LOCATION_PARTNER_BRANCH_TEMPlATE = '/core/api/v1/WebAdmin/Branch/List/Upload/Template'
  static URL_LOCATION_PARTNER_BRANCH_DELETE = '/core/api/v1/WebAdmin/Branch/Delete'
  static URL_LOCATION_PARTNER_BRANCH_UPLOAD = '/core/api/v1/WebAdmin/Branch/Upload'

  static URL_EV_LOCATION = '/ev/api/v1/master/charger/points/data'
  static URL_EV_LOCATION_DETAIL = '/ev/api/v1/master/charger/points/'
  static URL_EV_LOCATION_CREATE = '/ev/api/v1/master/charger/points'
  static URL_EV_LOCATION_UPDATE = '/ev/api/v1/master/charger/points/'
  static URL_EV_LOCATION_DELETE = '/ev/api/v1/master/charger/points/'
  static URL_EV_LOCATION_DOWNLOAD = '/ev/api/v1/master/charger/points/data/export'

  static URL_FAQ_CATEGORY = '/core/api/v1/WebAdmin/Faq/Category'
  static URL_FAQ_CATEGORY_CREATE = '/core/api/v1/WebAdmin/Faq/Category/Create'
  static URL_FAQ_CATEGORY_DETAIL = '/core/api/v1/WebAdmin/Faq/Category/Detail'
  static URL_FAQ_CATEGORY_UPDATE = '/core/api/v1/WebAdmin/Faq/Category/Update'
  static URL_FAQ_CATEGORY_STATUS = '/core/api/v1/WebAdmin/Faq/Category/Enable'
  static URL_FAQ_CATEGORY_DELETE = '/core/api/v1/WebAdmin/Faq/Category/Delete'
  static URL_FAQ_CATEGORY_DOWNLOAD = '/core/api/v1/WebAdmin/Faq/Category/Download'

  static URL_FAQ_LIST = '/core/api/v1/WebAdmin/Faq'
  static URL_FAQ_LIST_CREATE = '/core/api/v1/WebAdmin/Faq/Create'
  static URL_FAQ_LIST_DETAIL = '/core/api/v1/WebAdmin/Faq/Detail'
  static URL_FAQ_LIST_UPDATE = '/core/api/v1/WebAdmin/Faq/Update'
  static URL_FAQ_LIST_STATUS = '/core/api/v1/WebAdmin/Faq/Enable'
  static URL_FAQ_LIST_DELETE = '/core/api/v1/WebAdmin/Faq/Delete'
  static URL_FAQ_LIST_DOWNLOAD = '/core/api/v1/WebAdmin/Faq/Download'

  static URL_TERMS_AND_CONDITION = '/core/api/v1/WebAdmin/TermConditions'
  static URL_TERMS_AND_CONDITION_DROPDOWN = '/core/api/v1/WebAdmin/TermConditions/ddl'
  static URL_TERMS_AND_CONDITION_CREATE = '/core/api/v1/WebAdmin/TermConditions/Create'
  static URL_TERMS_AND_CONDITION_DETAIL = '/core/api/v1/WebAdmin/TermConditions/Detail'
  static URL_TERMS_AND_CONDITION_UPDATE = '/core/api/v1/WebAdmin/TermConditions/Update'
  static URL_TERMS_AND_CONDITION_STATUS = '/core/api/v1/WebAdmin/TermConditions/Enable'
  static URL_TERMS_AND_CONDITION_DELETE = '/core/api/v1/WebAdmin/TermConditions/Delete'
  static URL_TERMS_AND_CONDITION_DOWNLOAD = '/core/api/v1/WebAdmin/TermConditions/Download'

  static URL_PRIVACY_POLICY = '/core/api/v1/WebAdmin/PrivacyPolicy'
  static URL_PRIVACY_POLICY_CREATE = '/core/api/v1/WebAdmin/PrivacyPolicy/Create'
  static URL_PRIVACY_POLICY_DETAIL = '/core/api/v1/WebAdmin/PrivacyPolicy/Detail'
  static URL_PRIVACY_POLICY_UPDATE = '/core/api/v1/WebAdmin/PrivacyPolicy/Update'
  static URL_PRIVACY_POLICY_STATUS = '/core/api/v1/WebAdmin/PrivacyPolicy/Enable'
  static URL_PRIVACY_POLICY_DELETE = '/core/api/v1/WebAdmin/PrivacyPolicy/Delete'
  static URL_PRIVACY_POLICY_DOWNLOAD = '/core/api/v1/WebAdmin/PrivacyPolicy/Download'

  static URL_ACCESS_CONTROL_USERS_ACCOUNT = '/auth/api/v1/WebAdmin/UserAcc/List'
  static URL_ACCESS_CONTROL_USERS_ACCOUNT_DETAIL = '/auth/api/v1/WebAdmin/UserAcc/Detail/'
  static URL_ACCESS_CONTROL_USERS_ACCOUNT_CREATE = '/auth/api/v1/WebAdmin/User/Create'
  static URL_ACCESS_CONTROL_USERS_ACCOUNT_UPDATE = '/auth/api/v1/WebAdmin/UserAcc/update'
  static URL_ACCESS_CONTROL_USERS_ACCOUNT_RESET = '/auth/api/v1/WebAdmin/UserAcc/ResetPassword'
  static URL_ACCESS_CONTROL_USERS_ACCOUNT_DOWNLOAD = '/auth/api/v1/WebAdmin/UserAcc/Download'
  static URL_ACCESS_CONTROL_USERS_ACCOUNT_DROPDOWN =
    '/auth/api/v1/WebAdmin/UserAcc/ListAllUsers/Dropdown'

  static URL_ROLES_LIST = '/auth/api/v1/WebAdmin/Role'
  static URL_ROLES_LIST_DETAIL = '/auth/api/v1/WebAdmin/Role/'
  static URL_ROLES_LIST_CREATE = '/auth/api/v1/WebAdmin/Role/Add'
  static URL_ROLES_LIST_UPDATE = '/auth/api/v1/WebAdmin/Role/Update'
  static URL_ROLES_LIST_DROPDOWN = '/auth/api/v1/WebAdmin/Role/Dropdown'
  static URL_ROLES_LIST_TYPE_DROPDOWN = '/auth/api/v1/WebAdmin/RoleType/Dropdown'
  static URL_ROLES_LIST_DOWNLOAD = '/auth/api/v1/WebAdmin/Role/Download'

  static URL_ROLES_ASSIGNMENT = '/auth/api/v1/WebAdmin/MenuPermission/Users'
  static URL_ROLES_ASSIGNMENT_DETAIL = '/auth/api/v1/WebAdmin/MenuPermission/Users/Detail/'
  static URL_ROLES_ASSIGNMENT_UPDATE = '/auth/api/v1/WebAdmin/MenuPermission/Update'
  static URL_ROLES_ASSIGNMENT_DOWNLOAD = '/auth/api/v1/WebAdmin/MenuPermission/Download'

  static URL_ROLES_ASSIGNMENT_MAIN_MENU_LIST = '/auth/api/v1/WebAdmin/MainMenu'
  static URL_ROLES_ASSIGNMENT_SUB_MENU_LIST = '/auth/api/v1/WebAdmin/SubMenu/'
  static URL_ROLES_ASSIGNMENT_FUNCTION_LIST = '/auth/api/v1/WebAdmin/Function/'
  static URL_ROLES_ASSIGNMENT_ACCOUNT_LIST = '/auth/api/v1/WebAdmin/MenuPermission/Users/Dropdown'

  static URL_CATALOG_HYUNDAI_LIST = '/core/api/v1/WebAdmin/CarModel/Catalog/List'
  static URL_CATALOG_HYUNDAI_DOWNLOAD = '/core/api/v1/WebAdmin/CarModel/Catalog/Download'
  static URL_CATALOG_HYUNDAI_DELETE = '/core/api/v1/WebAdmin/CarModel/Catalog/Delete'
  static URL_CATALOG_HYUNDAI_DETAIL = '/core/api/v1/WebAdmin/CarModel/Catalog/Detail/'
  static URL_CATALOG_HYUNDAI_UPDATE = '/core/api/v1/WebAdmin/CarModel/Catalog/Update'

  static URL_CATALOG_HYUNDAI_CATALOG_LIST = '/core/api/v1/WebAdmin/Car/Catalog/List'
  static URL_CATALOG_HYUNDAI_CATALOG_DOWNLOAD = '/core/api/v1/WebAdmin/Car/Catalog/Download'
  static URL_CATALOG_HYUNDAI_CATALOG_DELETE = '/core/api/v1/WebAdmin/Car/Catalog/Delete/'
  static URL_CATALOG_HYUNDAI_CATALOG_DETAIL = '/core/api/v1/WebAdmin/Car/Catalog/Detail/'
  static URL_CATALOG_HYUNDAI_CATALOG_UPDATE = '/core/api/v1/WebAdmin/Car/Catalog/Update'
  static URL_CATALOG_HYUNDAI_CATALOG_CREATE = '/core/api/v1/WebAdmin/Car/Catalog/Create'
  static URL_BLAST_PUSH_NOTIF_CREATE = '/commbus/api/v1/PushNotification/Add/Blast'

  static URL_CATALOG_HYUNDAI = '/core/api/v1/car/catalog/hyundai/'
  static URL_CATALOG_HYUNDAI_CREATE = '/core/api/v1/WebAdmin/CarModel/Catalog/Create'

  static URL_CATALOG_POLICY_LIST = '/ev/api/v1/master/policy/data'
  static URL_CATALOG_POLICY = '/ev/api/v1/master/policy/'
  static URL_CATALOG_POLICY_CREATE = '/ev/api/v1/master/policy'
  static URL_CATALOG_POLICY_DOWNLOAD = '/ev/api/v1/master/policy/data/export'

  static URL_CATALOG_EV_CAR_BRAND_LIST = '/core/api/v1/WebAdmin/CarModel/Brand/List'
  static URL_CATALOG_EV_CAR_BRAND_DOWNLOAD = '/core/api/v1/WebAdmin/CarModel/Brand/Download'
  static URL_CATALOG_EV_CAR_BRAND_DETAIL = '/core/api/v1/WebAdmin/CarModel/Brand/Detail/'
  static URL_CATALOG_EV_CAR_BRAND_CREATE = '/core/api/v1/WebAdmin/CarModel/Brand/Create'
  static URL_CATALOG_EV_CAR_BRAND_UPDATE = '/core/api/v1/WebAdmin/CarModel/Brand/Update'
  static URL_CATALOG_EV_CAR_BRAND_DELETE = '/core/api/v1/WebAdmin/CarModel/Brand/Delete'

  static URL_CATALOG_EV_MODEL_LIST = '/core/api/v1/car/catalog/ev/data'
  static URL_CATALOG_EV_MODEL = '/core/api/v1/car/catalog/ev/'
  static URL_CATALOG_EV_MODEL_CREATE = '/core/api/v1/car/catalog/ev'
  static URL_CATALOG_EV_MODEL_DROPDOWN = '/core/api/v1/WebAdmin/CarModel/Catalog/Dropdown/EV'
  static URL_CATALOG_EV_MODEL_DOWNLOAD = '/core/api/v1/car/catalog/ev/data/export'

  static URL_PROFILING_CAR_INFORMATION_LIST = '/core/api/v1/WebAdmin/get/infoCar'
  static URL_PROFILING_CAR_INFORMATION_REGISTRATION_STATUS =
    '/core/api/v1/WebAdmin/get/masterStatus'
  static URL_PROFILING_CAR_INFORMATION_DETAIL = '/core/api/v1/WebAdmin/Detail/infoCar/'
  static URL_PROFILING_CAR_SHARING_STATUS = '/core/api/v1/WebAdmin/get/vehicleShared/'
  static URL_PROFILING_CAR_INFORMATION_APPROVE = '/core/api/v1/WebAdmin/approved/vin'
  static URL_PROFILING_CAR_INFORMATION_REJECT = '/core/api/v1/WebAdmin/reject/vin'
  static URL_PROFILING_CAR_INFORMATION_CHANGE_VIN = '/core/api/v1/WebAdmin/vin/update'
  static URL_PROFILING_CAR_INFORMATION_ADD_NOTES = '/core/api/v1/WebAdmin/notes/add'
  static URL_PROFILING_CAR_INFORMATION_UPDATE_ACCOUNT_STATUS =
    '/core/api/v1/WebAdmin/CarStatus/Update'
  static URL_PROFILING_CAR_INFORMATION_DOWNLOAD = '/core/api/v1/WebAdmin/infoCar/Download'
  static URL_PROFILING_CAR_SHARING_DELETE = 'core/api/v1/WebAdmin/delete/carSharing'

  static URL_OWNER_LIST = '/ev/api/v1/master/owner/data'
  static URL_OWNER_DROPDOWN = '/ev/api/v1/master/owner/ddl'
  static URL_OWNER_DETAIL = '/ev/api/v1/master/owner/'
  static URL_OWNER_CREATE = '/ev/api/v1/master/owner'
  static URL_OWNER_UPDATE = '/ev/api/v1/master/owner/'
  static URL_OWNER_DELETE = '/ev/api/v1/master/owner/'

  static URL_PARTNER_LIST = '/ev/api/v1/master/partner/data'
  static URL_PARTNER_DROPDOWN = '/ev/api/v1/master/partner/ddl'
  static URL_PARTNER_DETAIL = '/ev/api/v1/master/partner/'
  static URL_PARTNER_CREATE = '/ev/api/v1/master/partner'
  static URL_PARTNER_UPDATE = '/ev/api/v1/master/partner/'
  static URL_PARTNER_DELETE = '/ev/api/v1/master/partner/'

  static URL_POLICY_LIST = '/ev/api/v1/master/policy/data'
  static URL_POLICY_DROPDOWN = '/ev/api/v1/master/policy/ddl'
  static URL_POLICY_DETAIL = '/ev/api/v1/master/policy/'
  static URL_POLICY_CREATE = '/ev/api/v1/master/policy'
  static URL_POLICY_UPDATE = '/ev/api/v1/master/policy/'
  static URL_POLICY_DELETE = '/ev/api/v1/master/policy/'

  static URL_PROVIDER_LIST = '/ev/api/v1/master/provider/data'
  static URL_PROVIDER_DROPDOWN = '/ev/api/v1/master/provider/ddl'
  static URL_PROVIDER_DETAIL = '/ev/api/v1/master/provider/'
  static URL_PROVIDER_CREATE = '/ev/api/v1/master/provider'
  static URL_PROVIDER_UPDATE = '/ev/api/v1/master/provider/'
  static URL_PROVIDER_DELETE = '/ev/api/v1/master/provider/'

  static URL_EV_TOPUP_LIST = '/ev/api/v1/WebAdmin/get/transaction/topup'
  static URL_EV_TOPUP_DOWNLOAD = '/ev/api/v1/WebAdmin/transaction/topup/Download'
  static URL_EV_TOPUP_DETAIL = '/ev/api/v1/WebAdmin/get/transaction/topup/detail/'
  static URL_EV_TOPUP_STATUS_DROPDOWN = '/ev/api/v1/WebAdmin/transaction/topup/get/status/list'

  static URL_EV_PRICE_LIST = '/ev/api/v1/master/price/data'
  static URL_EV_PRICE_DOWNLOAD = '/ev/api/v1/master/price/data/export'
  static URL_EV_PRICE_DROPDOWN = '/ev/api/v1/master/price/ddl'
  static URL_EV_PRICE_CREATE = '/ev/api/v1/master/price'
  static URL_EV_PRICE_DETAIL = '/ev/api/v1/master/price/'
  static URL_EV_PRICE_UPDATE = '/ev/api/v1/master/price/'
  static URL_EV_PRICE_DELETE = '/ev/api/v1/master/price/'
  static URL_EV_PRICE_GENERATE = '/ev/api/v1/master/price/generate/code'

  static URL_EV_PRICE_CHARGE_BOX_GROUP_LIST = '/ev/api/v1/master/price/'
  static URL_EV_PRICE_CHARGE_BOX_GROUP_CREATE = '/ev/api/v1/master/price/'
  static URL_EV_PRICE_CHARGE_BOX_GROUP_UPDATE = '/ev/api/v1/master/price/'
  static URL_EV_PRICE_CHARGE_BOX_GROUP_DELETE = '/ev/api/v1/master/price/'

  static URL_EV_TRANSACTION_ONGOING_LIST = '/ev/api/v1/history/charging/ongoing/data'
  static URL_EV_TRANSACTION_ONGOING_DOWNLOAD = '/ev/api/v1/history/charging/ongoing/data/export'
  static URL_EV_TRANSACTION_ONGOING_DETAIL = '/ev/api/v1/history/charging/ongoing/'
  static URL_EV_TRANSACTION_FINISHED_LIST = '/ev/api/v1/history/charging/finished/data'
  static URL_EV_TRANSACTION_FINISHED_DOWNLOAD = '/ev/api/v1/history/charging/finished/data/export'
  static URL_EV_TRANSACTION_FINISHED_DETAIL = '/ev/api/v1/history/charging/finished/'

  static URL_EV_TRANSACTION_LOG_LIST = '/ev/api/v1/history/charging/event/'

  static URL_EV_CHARGE_POINT_LIST = '/ev/api/v1/master/charger/points/data'
  static URL_EV_CHARGE_POINT_DOWNLOAD = '/ev/api/v1/master/charger/points/data/export'
  static URL_EV_CHARGE_POINT_DROPDOWN = '/ev/api/v1/master/charger/points/ddl'
  static URL_EV_CHARGE_POINT_DETAIL = '/ev/api/v1/master/charger/points/'
  static URL_EV_CHARGE_POINT_CREATE = '/ev/api/v1/master/charger/points/'
  static URL_EV_CHARGE_POINT_UPDATE = '/ev/api/v1/master/charger/points/'
  static URL_EV_CHARGE_POINT_DELETE = '/ev/api/v1/master/charger/points/'
  static URL_EV_CHARGE_POINT_CODE = '/ev/api/v1/master/charger/points/generate/code'
  static URL_EV_CHARGE_POINT_RESYNC = '/ev/chargepoints'

  static URL_EV_CHARGE_POINT_BOX_GROUP_LIST = '/ev/api/v1/master/charger/points/'
  static URL_EV_CHARGE_POINT_BOX_GROUP_DOWNLOAD = '/ev/api/v1/master/charger/points/'

  static URL_EV_CHARGE_POINT_BOX_LIST = '/ev/api/v1/master/charger/points/'
  static URL_EV_CHARGE_POINT_BOX_DOWNLOAD = '/ev/api/v1/master/charger/points/'

  static URL_EV_CHARGE_BOX_GROUP_LIST = '/ev/api/v1/master/charger/group/data'
  static URL_EV_CHARGE_BOX_GROUP_DOWNLOAD = '/ev/api/v1/master/charger/group/data/export'
  static URL_EV_CHARGE_BOX_GROUP_DROPDOWN = '/ev/api/v1/master/charger/group/'
  static URL_EV_CHARGE_BOX_GROUP_DETAIL = '/ev/api/v1/master/charger/group/'
  static URL_EV_CHARGE_BOX_GROUP_CREATE = '/ev/api/v1/master/charger/group/point/'
  static URL_EV_CHARGE_BOX_GROUP_UPDATE = '/ev/api/v1/master/charger/group/'
  static URL_EV_CHARGE_BOX_GROUP_DELETE = '/ev/api/v1/master/charger/group/'
  static URL_EV_CHARGE_BOX_GROUP_CODE = '/ev/api/v1/master/charger/group/generate/code'

  static URL_EV_CHARGE_BOX_LIST = '/ev/api/v1/master/charger/box/data'
  static URL_EV_CHARGE_BOX_DOWNLOAD = '/ev/api/v1/master/charger/box/data/export'
  static URL_EV_CHARGE_BOX_DROPDOWN = '/ev/api/v1/master/charger/box/'
  static URL_EV_CHARGE_BOX_DETAIL = '/ev/api/v1/master/charger/box/'
  static URL_EV_CHARGE_BOX_CREATE = '/ev/api/v1/master/charger/box/group/'
  static URL_EV_CHARGE_BOX_UPDATE = '/ev/api/v1/master/charger/box/'
  static URL_EV_CHARGE_BOX_DELETE = '/ev/api/v1/master/charger/box/'
  static URL_EV_CHARGE_BOX_CODE = '/ev/api/v1/master/charger/box/generate/code'
  static URL_EV_CHARGE_BOX_RESYNC = '/ev/chargebox'

  static URL_EV_PARTNER_LIST = '/ev/api/v1/master/partner/data'
  static URL_EV_PARTNER_DETAIL = '/ev/api/v1/master/partner/'
  static URL_EV_PARTNER_CREATE = '/ev/api/v1/master/partner'
  static URL_EV_PARTNER_UPDATE = '/ev/api/v1/master/partner/'
  static URL_EV_PARTNER_DELETE = '/ev/api/v1/master/partner/'
  static URL_EV_PARTNER_DOWNLOAD = '/ev/api/v1/master/partner/data/export'

  static URL_EV_HISTORY_ONGOING_LIST = '/ev/api/v1/history/ev/ongoing'
  static URL_EV_HISTORY_ONGOING_DOWNLOAD = '/ev/api/v1/history/ev/ongoing/export'
  static URL_EV_HISTORY_FINISHED_LIST = '/ev/api/v1/history/ev/finished'
  static URL_EV_HISTORY_FINISHED_DOWNLOAD = '/ev/api/v1/history/ev/finished/export'
  static URL_EV_HISTORY_OVERVIEW_LIST = '/ev/api/v1/history/ev/summary'
  static URL_EV_HISTORY_DETAIL = '/ev/api/v1/history/ev/'

  static URL_EV_CHARGING_URL_LIST = '/ev/api/v1/master/qr-url/data'
  static URL_EV_CHARGING_URL_DOWNLOAD = '/ev/api/v1/master/qr-url/data/export'
  static URL_EV_CHARGING_URL_CREATE = '/ev/api/v1/master/qr-url'
  static URL_EV_CHARGING_URL_DETAIL = '/ev/api/v1/master/qr-url/'
  static URL_EV_CHARGING_URL_UPDATE = '/ev/api/v1/master/qr-url/'
  static URL_EV_CHARGING_URL_DELETE = '/ev/api/v1/master/qr-url/'

  static URL_VOUCHER_CATEGORY = '/core/api/v1/WebAdmin/Voucher-Category/List'
  static URL_VOUCHER_CATEGORY_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher-Category/Download'
  static URL_VOUCHER_CATEGORY_CREATE = '/core/api/v1/WebAdmin/Voucher-Category/Create'
  static URL_VOUCHER_CATEGORY_DELETE = '/core/api/v1/WebAdmin/Voucher-Category/Delete'
  static URL_VOUCHER_CATEGORY_DETAIL = '/core/api/v1/WebAdmin/Voucher-Category/Detail/'
  static URL_VOUCHER_CATEGORY_ENABLE = '/core/api/v1/WebAdmin/Voucher-Category/Enable'
  static URL_VOUCHER_CATEGORY_UPDATE = '/core/api/v1/WebAdmin/Voucher-Category/Update'
  static URL_VOUCHER_CATEGORY_DROPDOWN = '/core/api/v1/WebAdmin/Voucher-Category/DropdownList'

  static URL_VOUCHER_SUBCATEGORY = '/core/api/v1/WebAdmin/Voucher-SubCategory/List'
  static URL_VOUCHER_SUBCATEGORY_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher-SubCategory/Download'
  static URL_VOUCHER_SUBCATEGORY_CREATE = '/core/api/v1/WebAdmin/Voucher-SubCategory/Create'
  static URL_VOUCHER_SUBCATEGORY_DETAIL = '/core/api/v1/WebAdmin/Voucher-SubCategory/Detail/'
  static URL_VOUCHER_SUBCATEGORY_DELETE = '/core/api/v1/WebAdmin/Voucher-SubCategory/Delete'
  static URL_VOUCHER_SUBCATEGORY_UPDATE = '/core/api/v1/WebAdmin/Voucher-SubCategory/Update'
  static URL_VOUCHER_SUBCATEGORY_DROPDOWN =
    '/core/api/v1/WebAdmin/Voucher-SubCategory/DropdownList/'

  static URL_EV_HISTORY_PARTNER_KWH_LIMIT_REQUEST = '/ev/api/v1/master/charger/points/'
  static URL_EV_HISTORY_PARTNER_REQUEST = '/ev/api/v1/charging/partner/request/data'
  static URL_EV_HISTORY_PARTNER_AUTHORIZATION = '/ev/api/v1/charging/request/approval'
  static URL_EV_HISTORY_PARTNER_START_CHARGING = '/ev/api/v1/charging/request'
  static URL_EV_HISTORY_PARTNER_STOP_CHARGING = '/ev/api/v1/charging/request/stop/'
  static URL_EV_HISTORY_PARTNER_LIST = '/ev/api/v1/history/partner/data'
  static URL_EV_HISTORY_PARTNER_DOWNLOAD = '/ev/api/v1/history/partner/data/export'
  static URL_EV_HISTORY_PARTNER_SUMMARY = '/ev/api/v1/history/partner/summary'

  static URL_EV_QRCODE_LIST = '/ev/api/v1/master/charger/box/qr'
  static URL_EV_QRCODE_DELETE = '/ev/api/v1/master/charger/box/'
  static URL_EV_QRCODE_DETAIL = '/ev/api/v1/master/charger/box/'
  static URL_EV_QRCODE_DOWNLOAD = '/ev/api/v1/master/charger/box/qr/export'
  static URL_EV_QRCODE_CREATE = '/ev/api/v1/master/charger/box/'
  static URL_EV_CHARGE_USER = '/auth/api/v1/WebAdmin/UserAcc/List/Dropdown'
  static URL_EV_CAR_LIST = '/core/api/v1/WebAdmin/CarModel/Catalog/Dropdown/EV'

  static URL_TOKEN_CATEGORY = '/core/api/v1/WebAdmin/Voucher-Token-Category/List'
  static URL_TOKEN_CATEGORY_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher-Token-Category/Download'
  static URL_TOKEN_CATEGORY_DELETE = '/core/api/v1/WebAdmin/Voucher-Token-Category/Delete'
  static URL_TOKEN_CATEGORY_ENABLE = '/core/api/v1/WebAdmin/Voucher-Token-Category/Enable'
  static URL_TOKEN_CATEGORY_CREATE = '/core/api/v1/WebAdmin/Voucher-Token-Category/Create'
  static URL_TOKEN_CATEGORY_PARTNER = '/core/api/v1/WebAdmin/CompanyList/DropdownList'
  static URL_TOKEN_CATEGORY_DETAIL = '/core/api/v1/WebAdmin/Voucher-Token-Category/Detail/'
  static URL_TOKEN_CATEGORY_UPDATE = '/core/api/v1/WebAdmin/Voucher-Token-Category/Update'
  static URL_TOKEN_CATEGORY_DROPDOWN = '/core/api/v1/WebAdmin/Voucher-Token-Category/DropdownList'
  static URL_TOKEN_CATEGORY_UPLOAD = '/core/api/v1/WebAdmin/Voucher-Token-Category/Upload'
  static URL_TOKEN_CATEGORY_TEMPLATE =
    '/core/api/v1/WebAdmin/Voucher-Token-Category/DownloadTemplate'

  static URL_TOKEN_REQUEST = '/core/api/v1/WebAdmin/Voucher/TokenRequest/List'
  static URL_TOKEN_REQUEST_DETAIL = '/core/api/v1/WebAdmin/Voucher/TokenRequest/Detail/'
  static URL_TOKEN_STATUS_DROPDOWN =
    '/core/api/v1/WebAdmin/Voucher/TokenRequest/Dropdown/TaskStatus'

  static URL_TOKEN_REQUEST_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher/TokenRequest/Download'
  static URL_TOKEN_SUB_REQUEST = '/core/api/v1/WebAdmin/Voucher/Token/DetailRequest'
  static URL_TOKEN_LIST = '/core/api/v1/WebAdmin/Voucher/Token/List'
  static URL_TOKEN_DETAIL = '/core/api/v1/WebAdmin/Voucher/Token/Detail/'
  static URL_TOKEN_DETAIL_FAILED = '/core/api/v1/WebAdmin/Voucher/Token/DetailFailed/'
  static URL_TOKEN_REQUEST_CREATE = '/core/api/v1/WebAdmin/Voucher/TokenRequest/Create'
  static URL_TOKEN_REQUEST_UPDATE = '/core/api/v1/WebAdmin/Voucher/Token/Edit'
  static URL_TOKEN_REVOKE = '/core/api/v1/WebAdmin/Voucher/Token/Revoke'
  static URL_TOKEN_MOVE = '/core/api/v1/WebAdmin/Voucher/Token/Move'
  static URL_TOKEN_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher/Token/Download'
  static URL_TOKEN_SUBIMPORT_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher/Token/DownloadDetailImport'
  static URL_TOKEN_SUBREQUEST_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher/Token/DownloadDetailRequest'

  static URL_VOUCHER_LIST = '/core/api/v1/WebAdmin/VoucherNew/List'
  static URL_VOUCHER_DOWNLOAD = '/core/api/v1/WebAdmin/VoucherNew/Download'
  static URL_VOUCHER_ENABLED = '/core/api/v1/WebAdmin/VoucherNew/Enable'
  static URL_VOUCHER_DETAIL = '/core/api/v1/WebAdmin/VoucherNew/Detail/'
  static URL_VOUCHER_CREATE = '/core/api/v1/WebAdmin/VoucherNew/Create'
  static URL_VOUCHER_UPDATE = '/core/api/v1/WebAdmin/VoucherNew/Update'
  static URL_VOUCHER_DELETE = '/core/api/v1/WebAdmin/VoucherNew/Delete'
  static URL_VOUCHER_DROPDOWN = '/core/api/v1/WebAdmin/VoucherNew/Dropdown/List'
  static URL_VOUCHER_DROPDOWN_BY_ID = '/core/api/v1/WebAdmin/VoucherNew/DropdownByPartner/List/'
  static URL_VOUCHER_LIST_TOKEN = '/core/api/v1/WebAdmin/Voucher/Token/List/'

  static URL_VOUCHER_FAQ_LIST = '/core/api/v1/WebAdmin/Voucher/List/Info'
  static URL_VOUCHER_FAQ_DETAIL = '/core/api/v1/WebAdmin/Voucher/Detail/Edit/'
  static URL_VOUCHER_FAQ_UPDATE = '/core/api/v1/WebAdmin/Voucher/Detail/Update'

  static URL_TOKEN_IMPORT = '/core/api/v1/WebAdmin/Voucher/TokenImport/List'
  static URL_TOKEN_IMPORT_DETAIL = '/core/api/v1/WebAdmin/Voucher/TokenImport/Detail/'
  static URL_TOKEN_IMPORT_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher/TokenImport/Download'
  static URL_TOKEN_CAMPAIGN_TYPE = '/core/api/v1/VoucherCampaignCategory/DropdownList'
  static URL_TOKEN_CAMPAIGN = '/core/api/v1/VoucherCampaign/DropdownList'
  static URL_TOKEN_SUB_IMPORT = '/core/api/v1/WebAdmin/Voucher/Token/DetailImport'
  static URL_TOKEN_IMPORT_TEMPLATE = '/core/api/v1/WebAdmin/Voucher/TokenImport/DownloadTemplate'
  static URL_TOKEN_IMPORT_UPLOAD = '/core/api/v1/WebAdmin/Voucher/TokenImport/Create'

  static URL_EVENT_PRIZE_REDEMPTION = '/core/api/v1/Event/PrizeRedemption/Data'
  static URL_EVENT_PRIZE_REDEMPTION_DOWNLOAD = '/core/api/v1/Event/PrizeRedemption/Data/Export'
  static URL_EVENT_PRIZE_REDEMPTION_DETAIL = '/core/api/v1/Event/PrizeRedemption/Detail/'

  static URL_VOUCHER_BENEFITS = '/core/api/v1/WebAdmin/VoucherBenefit/List'
  static URL_VOUCHER_BENEFITS_DETAIL = '/core/api/v1/WebAdmin/VoucherBenefit/Detail/'
  static URL_VOUCHER_BENEFITS_DROPDOWN = '/core/api/v1/WebAdmin/VoucherNew/DropdownbyPartner/List/'
  static URL_VOUCHER_MEMBER_DROPDOWN = '/auth/api/v1/MemberType/DropdownList'
  static URL_BENEFITS_CATEGORY_DROPDOWN =
    '/core/api/v1/VoucherBenefit/VoucherCampaignCategory/DropdownList'
  static URL_BENEFITS_CONTENT_VOUCHER = '/core/api/v1/WebAdmin/VoucherBenefit/GetContentVoucher/'
  static URL_VOUCHER_BENEFITS_CREATE = '/core/api/v1/WebAdmin/VoucherBenefit/Create'
  static URL_VOUCHER_BENEFITS_UPDATE = '/core/api/v1/WebAdmin/VoucherBenefit/Update'
  static URL_VOUCHER_BENEFITS_DELETE = '/core/api/v1/WebAdmin/VoucherBenefit/Delete/'
  static URL_VOUCHER_BENEFITS_ENABLE = '/core/api/v1/WebAdmin/VoucherBenefit/Update/Status'
  static URL_VOUCHER_FILTER_DROPDOWN = '/core/api/v1/WebAdmin/VoucherBenefit/Voucher'
  static URL_PARTNER_FILTER_DROPDOWN = '/core/api/v1/WebAdmin/VoucherBenefit/Partner'
  static URL_VOUCHER_BENEFITS_DOWNLOAD = '/core/api/v1/WebAdmin/VoucherBenefit/Download'

  static URL_PROMO_CODE_BATCH_LIST = '/core/api/v1/PromoCode/Batch/Data'
  static URL_PROMO_CODE_BATCH_DOWNLOAD = '/core/api/v1/PromoCode/Batch/Data/Export'
  static URL_PROMO_CODE_BATCH_CREATE = '/core/api/v1/PromoCode/Batch'
  static URL_PROMO_CODE_BATCH_UPDATE = '/core/api/v1/PromoCode/Batch/'
  static URL_PROMO_CODE_BATCH_DELETE = '/core/api/v1/PromoCode/Batch/'
  static URL_PROMO_CODE_BATCH_DETAIL = '/core/api/v1/PromoCode/Batch/'
  static URL_PROMO_CODE_BATCH_DROPDOWN_STATUS = '/core/api/v1/PromoCode/Status/Ddl'
  static URL_PROMO_CODE_BATCH_DROPDOWN_REWARD = '/core/api/v1/PromoCode/RewardType/Ddl'

  static URL_PROMO_CODE_BATCH_SUB_LIST = '/core/api/v1/PromoCode/Batch/Codes/Data'
  static URL_PROMO_CODE_BATCH_SUB_DETAIL = '/core/api/v1/PromoCode/Batch/Codes/'
  static URL_PROMO_CODE_BATCH_SUB_DELETE = '/core/api/v1/PromoCode/Batch/Codes'
  static URL_PROMO_CODE_BATCH_SUB_DOWNLOAD = '/core/api/v1/PromoCode/Batch/Codes/Data/Export'
  static URL_PROMO_CODE_BATCH_SUB_VALIDITY = '/core/api/v1/PromoCode/Batch/Codes/Validity'
  static URL_PROMO_CODE_QR_CODE = '/core/api/v1/qrcode/base64'

  static URL_PROMO_CODE_LIST = '/core/api/v1/PromoCode/Single/Data'
  static URL_PROMO_CODE_DOWNLOAD = '/core/api/v1/PromoCode/Single/Data/Export'
  static URL_PROMO_CODE_CREATE = '/core/api/v1/PromoCode/Single'
  static URL_PROMO_CODE_UPDATE = '/core/api/v1/PromoCode/Single/'
  static URL_PROMO_CODE_DELETE = '/core/api/v1/PromoCode/Single/'
  static URL_PROMO_CODE_DETAIL = '/core/api/v1/PromoCode/Single/'

  static URL_PROMO_CODE_REDEMPTION_LIST = '/core/api/v1/PromoCode/Redemption/Data'
  static URL_PROMO_CODE_REDEMPTION_DOWNLOAD = '/core/api/v1/PromoCode/Redemption/Data/Export'
  static URL_PROMO_CODE_REDEMPTION_DETAIL = '/core/api/v1/PromoCode/Redemption/'
  static URL_PROMO_CODE_REDEMPTION_DROPDOWN = '/core/api/v1/PromoCode/Redemption/Status/Ddl'

  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_LIST = '/core/api/v1/Reservation/Event/Data'
  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_DETAIL = '/core/api/v1/Reservation/Event/'
  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_DETAIL_LABOR = '/core/api/v1/Reservation/Event/'
  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_BOOKING_STATUS =
    '/core/api/v1/Reservation/Event/ServiceStatus/Ddl'
  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_DEALER =
    '/core/api/v1/Dealer/Stall/Current/Ddl'
  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_DMS_REPAIR_STATUS =
    '/core/api/v1/Reservation/Event/DmsRepairStatus/Ddl'
  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_DROPDOWN_APPPOINTMENT_CONFIRMATION =
    '/core/api/v1/Reservation/Event/AppointmentConfirmation/Ddl'
  static URL_SERVICE_BOOKING_EVENT_TRANSACTION_DOWNLOAD = '/core/api/v1/Reservation/Event/Data/Export'
    static URL_SERVICE_BOOKING_EVENT_TRANSACTION_UPDATE_APPOINTMENT =
    '/core/api/v1/Reservation/Event/'

  static URL_SERVICE_BOOKING_EVENT_BANNER_LIST = '/core/api/v1/Banner/Event/Data'
  static URL_SERVICE_BOOKING_EVENT_BANNER_EVENT_DROPDOWN = '/core/api/v1/Event/ddl'
  static URL_SERVICE_BOOKING_EVENT_BANNER_CREATE = '/core/api/v1/Banner/Event'
  static URL_SERVICE_BOOKING_EVENT_BANNER_DETAIL = '/core/api/v1/Banner/Event/'
  static URL_SERVICE_BOOKING_EVENT_BANNER_DELETE = '/core/api/v1/Banner/Event/'
  static URL_SERVICE_BOOKING_EVENT_BANNER_UPDATE = '/core/api/v1/Banner/Event'

  static URL_NOTIFICATION_CUSTOMER_LIST = '/auth/api/v1/webAdmin/otp'
  static URL_NOTIFICATION_CUSTOMER_DETAIL = '/auth/api/v1/webAdmin/otp/detail/'

  static URL_VOUCHER_PACKAGE = '/core/api/v1/WebAdmin/VoucherPackage/List'
  static URL_VOUCHER_PACKAGE_DETAIL = '/core/api/v1/WebAdmin/VoucherPackage/Detail/'
  static URL_VOUCHER_PACKAGE_DROPDOWN = '/core/api/v1/WebAdmin/VoucherNew/DropdownbyCategory/List/'
  static URL_VOUCHER_PACKAGE_CREATE = '/core/api/v1/WebAdmin/VoucherPackage/Create'
  static URL_VOUCHER_PACKAGE_DELETE = '/core/api/v1/WebAdmin/VoucherPackage/delete/'
  static URL_VOUCHER_CARMODEL_DROPDOWN = '/core/api/v1/WebAdmin/VoucherPackage/CarModel'
  static URL_VOUCHER_PACKAGE_FILTER = '/core/api/v1/WebAdmin/VoucherPackage/Voucher'
  static URL_VOUCHER_PACKAGE_ENABLE = '/core/api/v1/WebAdmin/VoucherPackage/Update/Status'
  static URL_VOUCHER_PACKAGE_DOWNLOAD = '/core/api/v1/WebAdmin/VoucherPackage/Download'
  static URL_VOUCHER_PACKAGE_UPDATE = '/core/api/v1/WebAdmin/VoucherPackage/Update'

  static URL_VOUCHER_GIFT = '/core/api/v1/WebAdmin/VoucherGift/List'
  static URL_VOUCHER_GIFT_DETAIL = '/core/api/v1/WebAdmin/VoucherGift/Detail/'
  static URL_VOUCHER_GIFT_CREATE = '/core/api/v1/WebAdmin/VoucherGift/Create'
  static URL_VOUCHER_GIFT_DOWNLOAD = '/core/api/v1/WebAdmin/VoucherGift/Download'
  static URL_VOUCHER_GIFT_TEMPLATE = '/core/api/v1/WebAdmin/VoucherGift/Upload/Template'
  static URL_VOUCHER_GIFT_UPLOAD = '/core/api/v1/WebAdmin/VoucherGift/Upload'
  static URL_VOUCHER_GIFT_UPDATE = '/core/api/v1/WebAdmin/VoucherGift/Update'
  static URL_VOUCHER_GIFT_DELETE = '/core/api/v1/WebAdmin/VoucherGift/delete/'
  static URL_VOUCHER_GIFT_DROPDOWN = '/core/api/v1/WebAdmin/VoucherGift/Voucher'
  static URL_VOUCHER_ASSIGNTO_DROPDOWN = '/core/api/v1/WebAdmin/VoucherGift/AssignTo'
  static URL_VOUCHER_ASSIGNBY_DROPDOWN = '/core/api/v1/WebAdmin/VoucherGift/AssignBy'
  static URL_VOUCHER_GIFT_SEND = '/core/api/v1/WebAdmin/VoucherGift/Send'
  static URL_VOUCHER_GIFT_REDEMPTION = '/core/api/v1/WebAdmin/VoucherGiftRedemption/List'
  static URL_VOUCHER_REDEMPTION_DOWNLOAD = '/core/api/v1/WebAdmin/VoucherGiftRedemption/Download'
  static URL_VOUCHER_REDEMPTION_DROPDOWN = '/core/api/v1/WebAdmin/VoucherGiftRedemption/Voucher'
  static URL_REDEMPTION_REDEEMED_DROPDOWN = '/core/api/v1/WebAdmin/VoucherGiftRedemption/Redeemedby'
  static URL_REDEMPTION_GIFT_DROPDOWN = '/core/api/v1/WebAdmin/VoucherGiftRedemption/VoucherGift'
  static URL_GIFT_REDEMPTION_DETAIL = '/core/api/v1/WebAdmin/VoucherGiftRedemption/Detail/'

  static URL_VOUCHER_USAGE_LIST = '/core/api/v1/WebAdmin/Voucher/Usage/Data'
  static URL_VOUCHER_USAGE_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher/Usage/Data/Export'
  static URL_VOUCHER_USAGE_DETAIL = '/core/api/v1/WebAdmin/Voucher/Usage/'
  static URL_VOUCHER_USAGE_STATUS_DROPDOWN = '/core/api/v1/WebAdmin/Voucher/Usage/Status/Ddl'
  static URL_VOUCHER_USAGE_DATE_TIME_RANGE_DROPDOWN =
    '/core/api/v1/WebAdmin/Voucher/Usage/TimeRange/Ddl'

  static URL_VOUCHER_USAGE_TOKEN_LIST = '/core/api/v1/WebAdmin/Voucher/Usage/Token/Data'
  static URL_VOUCHER_USAGE_TOKEN_DOWNLOAD = '/core/api/v1/WebAdmin/Voucher/Usage/Token/Data/Export'
  static URL_VOUCHER_USAGE_TOKEN_DETAIL = '/core/api/v1/WebAdmin/Voucher/Usage/Token/'
  static URL_VOUCHER_USAGE_TOKEN_MOVE = '/core/api/v1/WebAdmin/Voucher/Usage/Token/Move'
  static URL_VOUCHER_USAGE_TOKEN_REVOKE = '/core/api/v1/WebAdmin/Voucher/Usage/Token/Revoke'

  static URL_CITY_LOUNGE = 'core/api/v1/BookingCityLounge/Data'

  static URL_TEST_DRIVE = '/core/api/v1/TestDrive/List'
  static URL_TEST_DRIVE_DOWNLOAD = '/core/api/v1/TestDrive/Export'
  static URL_TEST_DRIVE_DETAIL = '/core/api/v1/TestDrive/'
  static DEFAULT_TEST_DRIVE_FIELD = [
    'userId',
    'firstName',
    'carModel',
    'salesConsultant',
    'instagram',
    'bookingDate'
  ]
  static URL_COIN_LIST = '/core/api/v1/CoinSubmission/List'
  static URL_COIN_MEMBER = '/auth/api/v1/WebAdmin/ListUserByMemberCode'
  static URL_COIN_SUBMISSION = '/core/api/v1/CoinSubmission/Multiple'
}

export default CONSTANT
